body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar-title {
  font-size: 2rem;
  color: white;
  text-decoration: none;
}

.navbar-link {
  font-size: 1.25rem;
  color: white;
  text-decoration: none;
}

.navbar-link:hover {
  color: yellow;
}

.loadMore {
  cursor: pointer;
  width: 8rem;
  margin: 0 auto;
  text-align: center;
  padding: 0.25rem 0.5rem;
  background-color: #000000;
  color: #ffffff;
  font-weight: 600;
  border-radius: 1rem;
  opacity: 0.7;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.btn-close {
  background: none;
  border: none;
  color: #000;
  position: absolute;
  top: 5px;
  right: 5px;
  font-size: 16px;
  cursor: pointer;
}

.modal-image {
  /* width: 50%;
  float: right; */
}

pre {
  white-space: pre-wrap;
}

.activityIcon {
  width: 25px;
  height: 25px;
  margin-right: 10px;
}

.card-img {
  width: 320px;
  height: 360px;
  border: 1px solid #000;
  /* margin: 10px 0; */
}

.narrow {
  width: 100px;
  height: 150px;
}

.fill {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}
.fill img {
  flex-shrink: 100;
  min-width: 100%;
  min-height: 100%;
}

.contain {
  object-fit: contain;
}

.cover {
  object-fit: cover;
}

.none {
  object-fit: none;
}

.scale-down {
  object-fit: scale-down;
}

.card-description {
  text-overflow: ellipsis;
  overflow: hidden;
}
